@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face { 
	font-family: "slapstag_solid"; 
	src: url("../public/RAINYDAYS_SOLID.ttf"); 
}
@font-face { 
	font-family: "slapstag_hollow"; 
	src: url("../public/RAINYDAYS.ttf"); 
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
